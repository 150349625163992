/* Customized Defaults */
$primary: #092546;
$success: #34985E;
$dark: #092546;
$black: #000;

$link-color:                 #707070; // dark gray
$link-hover-color:           #092546; // dark blue

$font-family-sans-serif:      "Rubik";
$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$h1-font-size:                $font-size-base * 1.8;
$h2-font-size:                $font-size-base * 1.6;
$h3-font-size:                $font-size-base * 1.4;
$h4-font-size:                $font-size-base * 1.25;
$h5-font-size:                $font-size-base * 1.15;
$h6-font-size:                $font-size-base * 0.75;

$box-shadow:    0 .5rem 1rem rgba($black, .15);
$box-shadow-sm: 0 0 .5rem rgba($black, .1);

$form-check-input-width:                  1.5em;
// $form-check-min-height:                   $font-size-base * $line-height-base;
$form-check-padding-start:                $form-check-input-width + .5em;
$form-check-margin-bottom:                .3rem;

@import '../../node_modules/bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;600;700&display=swap');

body {
  background-color: #FCFAF8;
  color: #707070;
  padding-top: 3.5em;
  padding-bottom: 7em;
}

.no-display { display: none }
.error { color: red; font-size: .85rem; margin-top: .3rem;}
.pointer { cursor: pointer }

.btn { font-size: 90%; }
.btn-success, .btn-success:hover { color: white; }
.btn-outline-primary { border: 1px solid white; background-color: white; }

.btn-xs, .btn-group-xs > .btn {
    padding: 0.2rem 0.3rem;
    font-size: 0.75rem;
    border-radius: 0.2rem;
}

.bg-tan  { background-color: #FCFAF8 }
.bg-blue { background-color: #092546 }

#save-btn {
  position: fixed;
  width: 40%;
  left: 30%;
  bottom: 2.5em;
  color: white;
}

.horizontal-scroll {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.horizontal-thumb {
  display: inline-block;
  margin-right: .5rem;
  height: 150px;
}

textarea, input[type="text"], select { border: 1px solid #fff !important; border-radius: 12px !important; }
input[type="checkbox"] { border: 1px solid #fff !important; }

label:not([class='form-check-label']):not([class^='btn']):not([class^='list-group-item']) { font-size: 85%; color: #BABABA;}

h1 { font-weight: 600 }
h6 {
  text-transform: uppercase;
  font-weight: 600;
  color: #BABABA;
  letter-spacing: 2px;
}

.primary-photo {
  height: 30vh;
  background-size: cover;
  background-position: center;
  cursor: 'pointer';
}

#unsynced {
  position: fixed;
  cursor: pointer;
  bottom: 18px;
  right: 16px;
  z-index: 1000;
  font-size: $font-size-base * 1.8;
}

.file-container {
  overflow: hidden;
  position: relative;
}

.file-container [type=file] {
  cursor: inherit;
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  cursor: pointer;
}

.map-key > div {
  background-color: #ff0;
  border: 1px solid black;
  width: 22px;
  height:22px;
  margin-right: 6px;
  margin-bottom: 3px;
}

.img-thumb {
  border: 1px solid #ccc;
  float: left;
  height: 150px;
  margin: 0 6px 6px 0;
}

.list-group-checkable {
  display: grid;
  gap: .5rem;
  border: 0;
}
.list-group-checkable .list-group-item {
  cursor: pointer;
  border-radius: .5rem;
}
.list-group-item-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.list-group-item-check:hover + .list-group-item {
  background-color: var(--bs-light);
}
.list-group-item-check:checked + .list-group-item {
  color: #fff;
  background-color: var(--bs-blue);
}
.list-group-item-check[disabled] + .list-group-item,
.list-group-item-check:disabled + .list-group-item {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  height: 0;
  overflow: hidden;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.player-wrapper iframe {
  position: absolute;
  border: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
